import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import ConversicaRightRoundOne from "../images/ConversicaRightRoundOne.png"
import Shapefour from "../images/bannerElement/white-design-element-conversica.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import CertifiedConversicaPartner from "../images/icons/Conversica/Expert-support-from-a-certified-Conversica-partner.svg"
import IncreasedCampaign from "../images/icons/Conversica/Increased-campaign-performance.svg"
import MaximizeROI from "../images/icons/Conversica/Maximize-ROI-with-optimized-workflows-and-processes.svg"
import QuickStart from "../images/icons/Conversica/Quick-start-with-our-RevTech-integration-experience.svg"
import "../styles/conversica.css"

export default function AccountBasedMarketing() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Conversica | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <section id="ConversicaBanner">
          <div class="container">
            <div class="banner-holder">
              <div class="images">
                <div className="img-one image-shape">
                  <img src={Shapeone} alt="Image" />
                </div>
                <div className="img-two image-shape">
                  <img src={Shapetwo} alt="Image" />
                </div>
                <div className="img-three image-shape">
                  <img src={Shapethree} alt="Image" />
                </div>
                <div className="img-four image-shape">
                  <img src={Shapefour} alt="Image" />
                </div>
              </div>
              <div class="title">
                <div class="banner-title">
                  <h1>
                    Conversica + Position<sup>2</sup>
                  </h1>
                  <p>Empowering revenue teams</p>
                  <a
                    className="button"
                    id="3dsolutionvideo"
                    href="/contact-us/"
                  >
                    Get in touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="ConversicaPageIntro">
          <div class="container">
            <h2>Transforming your technology into a trusted team member</h2>
            <p>
              Position<sup>2</sup>, Conversica's premier partner, helps you
              integrate Revenue Digital Assistants (RDA) into your strategy,
              turning your technology from a tool to a trusted team member. We
              revolutionize how your Sales, Marketing, and Customer Experience
              teams operate.
            </p>
          </div>
        </section>
        <section id="ConversicaTextImageSection" class="section-greybg">
          <div class="container">
            <div class="text-image-holder">
              <div class="text-wrap">
                <h2>Our expertise at your service</h2>
                <p>
                  Our Conversica-certified staff supports you from setup to
                  ongoing management, providing expert guidance and hands-on
                  assistance. We augment your team, saving you time and
                  resources.
                </p>
                <p class="list-header">Focus areas:</p>
                <ul class="bullet-point">
                  <li>Conversation Management</li>
                  <li>Lead Management</li>
                  <li>Reporting</li>
                </ul>
              </div>
              <div class="img-wrap">
                <img alt="Copy write" src={ConversicaRightRoundOne} />
              </div>
            </div>
          </div>
        </section>
        <section id="ConversicaPagesecondIntro">
          <div class="container">
            <h2>Overcoming challenges</h2>
            <p>
              We understand the common pain points businesses face when adopting
              new technologies. That's why we're here to help you:
            </p>
            <ul class="bullet-point">
              <li>Set up and manage your RDA for optimal performance</li>
              <li>Integrate Conversica into your RevTech stack</li>
              <li>Improve deployment velocity at scale</li>
            </ul>
          </div>
        </section>
        <section id="ConversicaWeDeliver">
          <div className="container">
            <h2>Elevate your revenue strategy</h2>
            <p>
              Partner with Position<sup>2</sup> and Conversica to make every
              connection personal, engaging prospects and customers like never
              before. Let us empower your revenue teams to reach their full
              potential.
            </p>
            <div className="horizontalImageIconSec">
              <div className="moduleWrap">
                <div className="eachModule">
                  <div className="icons">
                    <img src={QuickStart} width={100} height={100} />
                  </div>
                  <div className="contents">
                    <p>Quick Start with Our RevTech Integration Experience</p>
                  </div>
                </div>
                <div className="eachModule">
                  <div className="icons">
                    <img src={IncreasedCampaign} width={100} height={100} />
                  </div>
                  <div className="contents">
                    <p>Increased Campaign Performance</p>
                  </div>
                </div>
                <div className="eachModule">
                  <div className="icons">
                    <img
                      src={CertifiedConversicaPartner}
                      width={100}
                      height={100}
                    />
                  </div>
                  <div className="contents">
                    <p>Expert Support from a Certified Conversica Partner</p>
                  </div>
                </div>
                <div className="eachModule">
                  <div className="icons">
                    <img src={MaximizeROI} width={100} height={100} />
                  </div>
                  <div className="contents">
                    <p>Maximize ROI with Optimized Workflows and Processes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="Conversicacontactus-section">
          <div class="container">
            <h2>Schedule a consultation to discuss your RDA integration</h2>
            <a className="button" id="3dsolutionvideo" href="/contact-us/">
              Get in touch
            </a>
          </div>
        </section>
      </Layout>
    </>
  )
}
